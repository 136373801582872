import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          {/* Location Section */}
          <div className="footer-col">
            <h4>
              <i className="gg-pin"></i> Location
            </h4>
            <ul>
              <li><Link to="#">Block 4, Plot 5411</Link></li>
              <li><Link to="#">Sabahiya, Al Ahmadi, 54404</Link></li>
              <li><Link to="#">Kuwait</Link></li>
            </ul>
          </div>

          {/* Contact Section */}
          <div className="footer-col">
            <h4>
              <i className="gg-mail"></i> Contact
            </h4>
            <ul>
              <li><Link to="mailto:info@example.com">info@thearkad.com</Link></li>
              <li><Link to="tel:+1234567890">+965 98998513</Link></li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="footer-bottom">
  <div className="footer-nav">
    <img src="/footer-logo.png" alt="The Arkad Logo" className="footer-logo-img" />
    <p>The Arkad © 2024.&nbsp;&nbsp;All rights reserved.</p>
  </div>
  <div className="made-by">
    <p>
      Developed by{" "}
      <a href="https://www.linkedin.com/company/whelveco" target="_blank" rel="noopener noreferrer">
        <img src="/whelveco-logo.png" alt="Made By" className="made-by-img" />
      </a>
    </p>
  </div>
</div>
      </div>
    </footer>
  );
}

export default Footer;
