import React from 'react';
import { Link } from 'react-router-dom';
import './Button.css';

const STYLES = ['btn--primary', 'btn--outline'];
const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({ buttonStyle, buttonSize, children, scrollTo, linkTo }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    // Scroll to the target element when the button is clicked
    const handleClick = () => {
        if (scrollTo && scrollTo.current) {
            scrollTo.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // If linkTo is passed, render a Link component, else render a button
    if (linkTo) {
        return (
            <Link to={linkTo} className={`btn ${checkButtonStyle} ${checkButtonSize}`}>
                {children}
            </Link>
        );
    }

    return (
        <button 
            className={`btn ${checkButtonStyle} ${checkButtonSize}`} 
            onClick={handleClick}  // Add onClick handler for scrolling
        >
            {children}
        </button>
    );
};
