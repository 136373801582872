import React from 'react';
import Carditem from './Carditem';
import './Cards.css';

// Services data
const services = [
  {
    title: "Man Power Supply",
    image: "/images/manpower.jpg",
    category: "Man Power Supply",
    link: "/services",
  },
  {
    title: "Building Maitanance",
    image: "/images/building-maintanance.jpg",
    category: "Building Maintanance",
    link: "/services",
  },
  {
    title: "Logistics",
    image: "/images/logistics.jpg",
    category: "Logistics",
    link: "/services",
  },
  {
    title: "Trading",
    image: "/images/trading.jpg",
    category: "Trading",
    link: "/services",
  },
];

function Cards() {
  return (
    <div className='cards'>
      <h1>Explore Our Services</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            {services.map((service, index) => (
              <Carditem
                key={index}
                title={service.title}
                image={service.image}
                category={service.category}
                link={service.link}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
