import React from 'react';
import './About.css';

export default function About() {
    return (
        <div className="about-page">
            {/* Scattered Images */}
            <img 
                src="/images/manpo-about-us.jpg" 
                alt="Manpower" 
                className="scattered-image top-left"
            />
            <img 
                src="/images/logi-about-us.jpg" 
                alt="Logistics" 
                className="scattered-image top-right"
            />
            <img 
                src="/images/buildingmat-about-us.jpg" 
                alt="Building Materials" 
                className="scattered-image bottom-left"
            />
            <img 
                src="/images/build-clean-about-us.jpg" 
                alt="Building Cleaning" 
                className="scattered-image bottom-right"
            />

            {/* Main Card */}
            <div className="about-card">
                <h1 className="about-title">About Us</h1>
                <p className="about-text">
                    Welcome to The Arkad Group. Our mission is to create exceptional value through 
                    innovation, passion, and commitment. 
                    As a dynamic team of professionals, we are dedicated to helping our clients achieve their goals and exceed 
                    their expectations
                </p>
                <p className="about-text">
                    Founded in 2024, we pride ourselves on our values of integrity, collaboration, and excellence. 
                    We strive to foster an environment of growth and impact, making a positive difference in the industries 
                    we serve.
                </p>
                <p className="about-text">
                    Thank you for choosing The Arkad. Together, we can build a brighter future!
                </p>
            </div>
        </div>
    );
}
