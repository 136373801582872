import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';
import './Home.css';

function Home() {
    return (
        <>
            <HeroSection />

            {/* Home About Us Section */}
            <div className="home-about-us">
                <div className="home-about-text">
                    <h2>About Us</h2>
                    <p>
                    Welcome to The Arkad Group. Our mission is to create exceptional value through 
                    innovation, passion, and commitment. 
                    As a dynamic team of professionals, we are dedicated to helping our clients achieve their goals and exceed 
                    their expectations
                    </p>
                </div>
                <div className="home-about-image">
                    <img src="https://cdn.prod.website-files.com/62434fa732124a0fb112aab4/62434fa732124a2a3312aae1_placeholder%203.svg" alt="About Us" />
                </div>
            </div>

            <Cards />
        </>
    );
}

export default Home;
