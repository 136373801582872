import React from 'react';
import './Services.css'; // Ensure this points to the correct CSS file

export default function Services() {
    return (
        <div className="services-container">
            <h1 className="services-header">Our Services</h1>
            <div className="services-list">
                <div className="service-card">
                    <img src="/images/manpower.jpg" alt="Manpower Supply" className="service-image" />
                    <h2>Manpower Supply</h2>
                    <p>We provide skilled and unskilled manpower for various industries such as construction, event management, and more.</p>
                    <ul>
                        <li>Temporary and long-term staffing</li>
                        <li>Trained and vetted professionals</li>
                        <li>Emergency manpower solutions</li>
                    </ul>
                </div>
                <div className="service-card">
                    <img src="/images/building-maintanance.jpg" alt="Building Cleaning Services" className="service-image" />
                    <h2>Building Cleaning Services</h2>
                    <p>We offer professional cleaning services for both residential and commercial buildings. Our services include regular cleaning, deep cleaning, and specialized services like carpet cleaning and window washing.</p>
                    <ul>
                        <li>Daily, weekly, or one-time cleaning</li>
                        <li>Eco-friendly cleaning supplies</li>
                        <li>Customized service packages</li>
                    </ul>
                </div>
                <div className="service-card">
                    <img src="/images/logistics.jpg" alt="Logistics" className="service-image" />
                    <h2>Logistics</h2>
                    <p>We ensure that your materials and products are delivered on time and in perfect condition. Our logistics services include transportation, warehousing, and distribution.</p>
                    <ul>
                        <li>Reliable transportation of goods</li>
                        <li>Warehousing and inventory management</li>
                        <li>Real-time shipment tracking</li>
                    </ul>
                </div>
                <div className="service-card">
                    <img src="/images/trading.jpg" alt="Trading" className="service-image" />
                    <h2>Trading (Building & Cleaning Materials)</h2>
                    <p>We specialize in the trade of building materials and cleaning products. Whether you’re looking for raw materials for construction or cleaning supplies, we offer high-quality products at competitive prices.</p>
                    <ul>
                        <li>Building materials: cement, steel, bricks, and more</li>
                        <li>Cleaning materials: detergents, tools, and equipment</li>
                        <li>Competitive pricing and reliable supply chains</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
