import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './HeroNavbar.css'; // Custom styles for the hero navbar

function HeroNavbar() {
  const [click, setClick] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const lastScrollY = useRef(0);
  const ticking = useRef(false); // Ref for scroll debounce
  const menuTimeout = useRef(null); // Ref for menu timeout

  // Toggle menu on click
  const handleClick = () => {
    setClick(!click);
    if (!click) {
      // Set a timeout to close the menu after 5 seconds if it's opened
      menuTimeout.current = setTimeout(() => {
        setClick(false);
      }, 5000);
    } else {
      // Clear the timeout if the menu is being closed before 5 seconds
      if (menuTimeout.current) {
        clearTimeout(menuTimeout.current);
      }
    }
  };

  // Close mobile menu
  const closeMobileMenu = () => {
    setClick(false);
    if (menuTimeout.current) {
      clearTimeout(menuTimeout.current); // Clear timeout when closing menu
    }
  };

  // Handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Use requestAnimationFrame to debounce scroll events
      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          if (currentScrollY > lastScrollY.current && currentScrollY > 50) {
            // Hide navbar on scroll down
            setShowNavbar(false);
          } else {
            // Show navbar on scroll up
            setShowNavbar(true);
          }
          lastScrollY.current = currentScrollY;
          ticking.current = false;
        });
        ticking.current = true;
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (menuTimeout.current) {
        clearTimeout(menuTimeout.current); // Clean up timeout on unmount
      }
    };
  }, []);

  // Effect to handle body scroll blocking
  useEffect(() => {
    if (click) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto'; // Re-enable scrolling
    }
  }, [click]);

  return (
    <nav
      className={`hero-navbar ${
        showNavbar ? '' : 'hero-navbar-hidden'
      } ${click ? 'menu-active' : ''}`}
    >
      <div className="hero-navbar-container">
        <Link to="/" className="hero-navbar-logo">
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="The Arkad Logo"
            className="hero-navbar-logo-img"
          />
        </Link>
        <div
          className={`hero-menu-icon ${click ? 'active' : ''}`}
          onClick={handleClick}
        >
          <i className={click ? 'gg-close' : 'gg-menu'}></i>
        </div>
        <ul className={click ? 'hero-nav-menu active' : 'hero-nav-menu'}>
          <li>
            <Link to="/" className="hero-nav-links" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="hero-nav-links"
              onClick={closeMobileMenu}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              className="hero-nav-links"
              onClick={closeMobileMenu}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="hero-nav-links"
              onClick={closeMobileMenu}
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default HeroNavbar;
