import React from 'react';
import '../App.css';
import { Button } from './Button';
import Navbar from './HeroNavbar';
import './HeroSection.css';


function HeroSection() {
  return (
    <div className='hero-container'>
      <Navbar className="hero-navbar" />
      <video src={`${process.env.PUBLIC_URL}/videos/video.mp4`} autoPlay loop muted playsInline />
      <h1>Leading the World in a Manner</h1>
      <p>Welcome to The Arkad Group</p>
      <div className='hero-btns'>
      <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          linkTo="/services"  // Link to the services page
        >
          Learn More
        </Button>
      </div>
    </div>
  )
}

export default HeroSection
