import React, { useState } from 'react';
import './Contact.css';

export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert('Message Sent!');
    };

    return (
        <div className="contact-section">
            <div className="contact-container">
                <h1 className="contact-title">GET IN TOUCH</h1>
                <p className="contact-description">
                    We’d love to hear from you! Whether you have a question, feedback, or just want to say hello, feel free to reach out.
                </p>

                <div className="contact-wrapper">
                    {/* Left side - Form */}
                    <div className="contact-form-container">
                        <form 
                            className="contact-form" 
                            action="https://formspree.io/f/your-form-id" 
                            method="POST"
                            onSubmit={handleSubmit}
                        >
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input 
                                    type="text" 
                                    id="name" 
                                    name="name" 
                                    value={formData.name} 
                                    onChange={handleChange} 
                                    required 
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input 
                                    type="email" 
                                    id="email" 
                                    name="email" 
                                    value={formData.email} 
                                    onChange={handleChange} 
                                    required 
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea 
                                    id="message" 
                                    name="message" 
                                    value={formData.message} 
                                    onChange={handleChange} 
                                    required 
                                />
                            </div>
                            <button type="submit" className="submit-btn">Send Message</button>
                        </form>
                    </div>

                    {/* Right side - Contact Info */}
                    <div className="contact-info-container">
                        <div className="contact-info">
                            <div className="info-item">
                                <i className="gg-phone info-icon"><svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="..."></path>
                                </svg></i>
                                <a href="tel:+96598998513" className="info-text">+965 98998513</a>
                            </div>
                            <div className="info-item">
                                <i className="gg-mail info-icon"><svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="..."></path>
                                </svg></i>
                                <a href="mailto:info@thearkad.com" className="info-text">info@thearkad.com</a>
                            </div>
                            <div className="info-item">
                                <i className="gg-pin info-icon"><svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="..."></path>
                                </svg></i>
                                <a href="https://www.google.com/maps?q=123+Business+Street,+City,+Country" target="_blank" rel="noopener noreferrer" className="info-text">
                                Block 4, Plot 5411 Sabahiya, Al Ahmadi, 54404, Kuwait
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
